import React from 'react';
import { Link, graphql } from 'gatsby';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';
import ReactPlayer from 'react-player';
import SEO from '../components/SEO';

// components
import Layout from '../components/Layout';
import Header from '../components/Header';
import Img from 'gatsby-image';
import Gallery from '../components/Gallery';
import Button from '../components/Button';

import css from '../css/templates/project.module.css';
import closeIcon from '../images/close.svg';

export default ({ data, location }) => {
  const meta = data.airtable.data;
  const title = meta.title;
  const notesHtml = meta.notes.childMarkdownRemark.html;
  const notesExcerpt = meta.notes.childMarkdownRemark.excerpt;
  const cover = meta.cover_image.localFiles[0].childImageSharp.fluid;
  const coverUrl = location.origin + meta.cover_image.localFiles[0].childImageSharp.fixed.src;
  const videoUrl = meta.video_url;
  const gallery = meta.gallery.localFiles;
  const url = meta.url;
  const dateCreated = meta.created;
  const authorsArray = meta.authors;
  const organizationsArray = meta.organizations;
  const permalink = location.href;

  var hero;
  var authorList;

  if (videoUrl) {
    hero = (
      <div className={css.videoWrapper}>
        <ReactPlayer
          url={videoUrl}
          className={css.reactPlayer}
          controls
          width="100%"
          height="100%"
          light={true}
          config={{
            youtube: {
              playerVars: {
                showinfo: 0,
                modestbranding: 1,
                rel: 0
              }
            }
          }}
        />
      </div>
    );
  } else {
    hero = <Img fluid={cover} />;
  }

  if (authorsArray) {
    const allAuthors = authorsArray.map(person => (
      <span>
        <a href={person.data.url} target="_blank">
          {person.data.first_name} {person.data.last_name}
        </a>
      </span>
    ));
    authorList = <span className={css.authorList}>by {allAuthors}</span>;
  } else {
    const allOrganizations = organizationsArray.map(organization => (
      <span>
        <a href={organization.data.url} target="_blank">
          {organization.data.name}
        </a>
      </span>
    ));
    authorList = <span className={css.authorList}>by {allOrganizations}</span>;
  }

  return (
    <ModalRoutingContext>
      {({ modal, closeTo }) => (
        <div>
          {modal ? (
            <div>
              <div className="closeModal">
                <Link
                  to={closeTo}
                  state={{
                    noScroll: true
                  }}
                >
                  <img src={closeIcon} />
                </Link>
              </div>
              <article className={`raised ${css.itemModal}`}>
                <div className={css.hero}>{hero}</div>
                <div className={css.body}>
                  <h1 className={`${css.title} h2`}>{title}</h1>
                  <div className={css.author}>{authorList}</div>
                  <div className={css.notes} dangerouslySetInnerHTML={{ __html: notesHtml }} />
                  <div className={css.gallery}>
                    <Gallery photos={gallery}></Gallery>
                  </div>
                  <div className={css.footer}>
                    <div className={css.link}>
                      <Button type="externalLink" url={url} size="small">
                        Visit project website
                      </Button>
                    </div>
                    <div className={css.date}>Added on {dateCreated}</div>
                  </div>
                </div>
              </article>
            </div>
          ) : (
            <Layout>
              <SEO
                title={`${title} | Cyberbiomes`}
                description={notesExcerpt}
                permalink={permalink}
                cover={coverUrl}
              />
              <Header />
              <article className={`raised ${css.item}`}>
                <div className={css.hero}>{hero}</div>
                <div className={css.body}>
                  <h1 className={`${css.title} h2`}>{title}</h1>
                  <div className={css.author}>{authorList}</div>
                  <div className={css.notes} dangerouslySetInnerHTML={{ __html: notesHtml }} />
                  <div className={css.gallery}>
                    <Gallery photos={gallery}></Gallery>
                  </div>
                  <div className={css.footer}>
                    <div className={css.link}>
                      <Button type="externalLink" url={url} size="small">
                        Visit project website
                      </Button>
                    </div>
                    <div className={css.date}>Added on {dateCreated}</div>
                  </div>
                </div>
              </article>
            </Layout>
          )}
        </div>
      )}
    </ModalRoutingContext>
  );
};

export const query = graphql`
  query($title: String!) {
    airtable(table: { eq: "projects" }, data: { title: { eq: $title } }) {
      data {
        title
        created(formatString: "D MMMM, YYYY")
        url
        video_url
        authors {
          id
          data {
            first_name
            last_name
            url
          }
        }
        organizations {
          id
          data {
            name
            url
          }
        }
        notes {
          childMarkdownRemark {
            excerpt
            html
          }
        }
        gallery {
          localFiles {
            childImageSharp {
              fixed(width: 1920) {
                src
              }
              fluid(maxWidth: 200, maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        cover_image {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 800, maxHeight: 450) {
                ...GatsbyImageSharpFluid
              }
              fixed(width: 1200, height: 630) {
                src
              }
            }
          }
        }
      }
    }
  }
`;
