import React from 'react';
import Img from 'gatsby-image';
import Lightbox from 'react-images';

// css
import css from '../css/components/Gallery.module.css';

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareOpen: false,
      anchorEl: null,
      lightbox: false,
      count: props.count,
      photos: props.photos.map(photo => Object.assign({ src: photo.childImageSharp.fixed.src }))
    };
  }

  gotoPrevLightboxImage() {
    const { photo } = this.state;
    this.setState({ photo: photo - 1 });
  }

  gotoNextLightboxImage() {
    const { photo } = this.state;
    this.setState({ photo: photo + 1 });
  }

  openLightbox(photo, event) {
    event.preventDefault();
    this.setState({ lightbox: true, photo });
  }

  closeLightbox() {
    this.setState({ lightbox: false });
  }

  render() {
    const { photos, count } = this.props;
    return (
      <div>
        <div className={css.gallery} style={{ gridTemplateColumns: `repeat(${count}, 1fr)` }}>
          {photos.map((photo, i) => (
            <a
              key={i}
              href={photo.childImageSharp.fixed.src}
              onClick={e => this.openLightbox(i, e)}
              className={css.thumbnail}
            >
              <Img fluid={photo.childImageSharp.fluid} />
            </a>
          ))}
        </div>
        <Lightbox
          backdropClosesModal
          images={this.state.photos}
          currentImage={this.state.photo}
          isOpen={this.state.lightbox}
          onClickPrev={() => this.gotoPrevLightboxImage()}
          onClickNext={() => this.gotoNextLightboxImage()}
          onClose={() => this.closeLightbox()}
          width={1600}
          imageCountSeparator=" / "
          spinnerColor="#5a5a5b"
        />
      </div>
    );
  }
}

export default Gallery;
