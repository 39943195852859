import React from 'react';
import { Link } from 'gatsby';
import Logo from '../components/Logo';

import css from '../css/components/Header.module.css';

const Header = () => (
  <header className={css.header}>
    <div className={`${css.logo} raised`}>
      <Link to="/">
        <Logo width="72" />
      </Link>
    </div>
  </header>
);

export default Header;
